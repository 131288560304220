.world-clock {
  padding: 0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--header-bg);
  border-bottom: 1px solid var(--header-border);
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.settings-group {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
}

.setting-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.setting-item input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  accent-color: var(--accent);
  cursor: pointer;
}

.clock-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px 24px;
}

.clock-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin: 0 auto;
}

.clock-item {
  background-color: var(--card-bg);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px var(--shadow);
  user-select: none;
  position: relative;
}

.clock-item.dragging {
  background-color: var(--card-bg);
  box-shadow: 0 8px 16px var(--shadow);
  z-index: 1;
  opacity: 0.8;
}

.city-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  height: 20px;
}

.drag-handle-wrapper {
  display: flex;
  align-items: center;
  cursor: grab;
  touch-action: none;
  padding: 0;
  height: 20px;
}

.drag-handle {
  opacity: 0.5;
  transition: opacity 0.2s;
  color: var(--icon-color);
  width: 16px;
  height: 16px;
  display: block;
}

.drag-handle:hover {
  opacity: 1;
}

.clock-item h2 {
  margin: 0;
  color: var(--text-secondary);
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.time {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  letter-spacing: -0.5px;
}

.date {
  color: var(--text-secondary);
  font-size: 0.85rem;
  font-weight: 400;
  margin-top: 4px;
}

.theme-selector {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
}

.theme-selector select {
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background-color: var(--card-bg);
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s ease;
}

.theme-selector select:hover {
  border-color: var(--accent);
}

.theme-selector select:focus {
  border-color: var(--accent);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.menu-button {
  display: none;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--text-primary);
}

.settings-menu {
  display: none;
}

@media (max-width: 600px) {
  .settings-desktop {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .settings-menu {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 12px;
    padding: 16px;
    margin-top: 8px;
    box-shadow: 0 4px 12px var(--shadow);
    z-index: 1000;
  }

  .settings-menu .settings-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .settings-menu .theme-selector {
    width: 100%;
  }

  .settings-menu .theme-selector select {
    width: 100%;
  }

  .settings-menu .setting-item {
    width: 100%;
  }

  .header-content {
    padding: 8px 16px;
  }

  .header h1 {
    font-size: 1.25rem;
  }

  .header-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .settings-group {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0;
  }

  .theme-selector,
  .setting-item {
    font-size: 0.875rem;
  }

  .theme-selector {
    flex: 1;
    min-width: 140px;
  }

  .world-clock {
    padding: 8px;
  }

  .clock-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .settings-group {
    margin: 0 10px 20px;
  }

  .clock-container {
    padding: 16px 8px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .clock-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.language-selector {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
}

.language-selector select {
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background-color: var(--card-bg);
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s ease;
}

/* RTL 支持 */
[dir="rtl"] .header-actions {
  flex-direction: row-reverse;
}

[dir="rtl"] .drag-handle-wrapper {
  left: auto;
  right: 10px;
}

.add-city {
  position: relative;
}

.add-city-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background: none;
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.add-city-button:hover {
  background-color: var(--border);
}

.city-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 12px;
  margin: 0 -24px;
}

.city-option {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: var(--card-bg);
  border: 1px solid var(--border);
  border-radius: 8px;
  transition: all 0.2s ease;
  height: auto;
  gap: 4px;
}

.city-option:hover {
  border-color: var(--accent);
  background-color: var(--hover-bg);
  transform: translateY(-1px);
}

.city-option-name {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary);
  margin: 0;
}

.city-option-country {
  font-size: 0.75rem;
  color: var(--text-secondary);
  opacity: 0.8;
}

@media (max-width: 600px) {
  .city-list {
    grid-template-columns: 1fr;
    max-height: 70vh;
  }
}

.remove-button {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 28px;
  height: 28px;
  border: none;
  background: none;
  color: var(--text-secondary);
  font-size: 18px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease, color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.clock-item:hover .remove-button {
  opacity: 1;
}

.remove-button:hover {
  color: #ef4444;
  background-color: rgba(239, 68, 68, 0.1);
}

@media (max-width: 600px) {
  .header-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .add-city select {
    width: 100%;
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog {
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 12px var(--shadow);
}

.dialog h3 {
  margin: 0 0 16px 0;
  color: var(--text-primary);
  font-size: 1.25rem;
}

.dialog p {
  margin: 0 0 24px 0;
  color: var(--text-secondary);
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.dialog-button {
  padding: 8px 16px;
  border: 1px solid var(--border);
  border-radius: 6px;
  background: none;
  color: var(--text-primary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dialog-button:hover {
  background-color: var(--border);
}

.dialog-button-danger {
  background-color: #ef4444;
  border-color: #ef4444;
  color: white;
}

.dialog-button-danger:hover {
  background-color: #dc2626;
  border-color: #dc2626;
}

@media (max-width: 600px) {
  .dialog {
    margin: 16px;
  }
}

.city-info {
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
}

.city-name {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.country-name {
  font-size: 0.75rem;
  color: var(--text-secondary);
  opacity: 0.8;
  margin-top: 2px;
  line-height: 1;
}

.time-diff {
  font-size: 0.85rem;
  color: var(--text-secondary);
  margin-top: 4px;
}

.add-city-modal {
  width: 90% !important;
  max-width: 800px !important;
}

.add-city-modal .ant-modal-body {
  padding: 24px 24px 12px;
}

/* 调整卡片内容的内边距和布局 */
.ant-card.clock-card {
  height: 100%;
}

.ant-card.clock-card .ant-card-head {
  padding: 10px 14px;
  min-height: auto;
  border-bottom: 1px solid var(--border);
}

.ant-card.clock-card .ant-card-head-title {
  padding: 0;
}

.ant-card.clock-card .ant-card-extra {
  padding: 0;
}

.ant-card.clock-card .ant-card-body {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.time {
  font-size: 2rem;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center;
  margin: 2px 0;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.date {
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin: 1px 0;
  line-height: 1.3;
}

/* 调整拖拽手柄和城市信息的布局 */
.clock-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.city-info {
  flex: 1;
  min-width: 0;
  padding: 1px 0;
}

.city-name {
  line-height: 1.2;
}

.country-name {
  line-height: 1.1;
  margin-top: 2px;
}

.time-diff {
  margin-top: 3px;
  line-height: 1.1;
}

/* 删除确认框样式 */
.remove-city-modal .ant-modal-content {
  border-radius: 8px;
}

.remove-city-modal .ant-modal-body {
  padding: 24px;
  text-align: center;
}

/* 时钟卡片删除按钮样式 */
.clock-card-delete {
  opacity: 0;
  transition: opacity 0.3s;
}

.clock-card:hover .clock-card-delete {
  opacity: 1;
}

/* 设置页面样式优化 */
.settings-modal {
  max-width: 500px;
}

.settings-modal .ant-modal-content {
  border-radius: 8px;
}

.settings-content {
  padding: 0 8px;
}

.settings-section {
  margin-bottom: 24px;
}

.settings-section:last-child {
  margin-bottom: 0;
}

.settings-section-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 16px;
}

.settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 4px 8px;
}

.settings-item:last-child {
  margin-bottom: 0;
}

.settings-label {
  font-size: 14px;
  color: var(--text-secondary);
}

.settings-divider {
  margin: 24px 0;
}

.settings-modal .ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.clock-card-wrapper {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.clock-card-wrapper.dragging {
  opacity: 0.8;
  transform: scale(1.02);
}

.clock-card {
  transition: all 0.3s ease;
}

.clock-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.clock-card-delete {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.clock-card:hover .clock-card-delete {
  opacity: 1;
}