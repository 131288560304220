.App {
  text-align: center;
  min-height: 100vh;
  padding: 0;
}

.App > *:not(.header) {
  padding: 0 20px;
}

/* 时钟网格布局 */
.clock-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* 头部样式 */
.header {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 12px 24px;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

[data-theme='dark'] .header {
  background-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.06);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  background: linear-gradient(45deg, var(--text-primary), var(--accent));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 时钟卡片样式 */
.clock-card {
  height: 100%;
  cursor: default;
}

.clock-card .ant-card-body {
  padding: 16px;
}

.clock-header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.city-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.city-name {
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--text-primary);
}

.time-diff {
  font-size: 0.8rem;
  color: var(--text-secondary);
  font-weight: normal;
}

.time {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 8px 0;
  color: var(--text-primary);
}

.date {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

/* 响应式布局 */
@media (max-width: 600px) {
  .clock-grid {
    grid-template-columns: 1fr;
    padding: 12px;
    gap: 12px;
  }

  .header {
    padding: 10px 16px;
  }

  .header h1 {
    font-size: 1.25rem;
  }

  .header-actions {
    gap: 6px;
  }

  .header-actions .ant-select {
    width: 80px !important;
  }

  .header-actions .ant-switch {
    min-width: 46px;
    height: 22px;
  }

  .header-actions .ant-switch .ant-switch-inner-checked,
  .header-actions .ant-switch .ant-switch-inner-unchecked {
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .clock-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 暗色主题适配 */
[data-theme='dark'] .header {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}

/* 浮动添加按钮 */
.floating-add-button {
  position: fixed;
  right: 32px;
  bottom: 32px;
  width: 56px !important;
  height: 56px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;
  transition: all 0.3s;
  padding: 0 !important;
}

.floating-add-button .anticon {
  font-size: 24px;
}

.floating-add-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .floating-add-button {
    right: 20px;
    bottom: 20px;
    width: 48px !important;
    height: 48px !important;
  }

  .floating-add-button .anticon {
    font-size: 22px;
  }
}

/* 设置面板样式 */
.settings-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.setting-item {
  padding: 4px 0;
}

.setting-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 12px;
}

.setting-title {
  flex: 1;
}

.setting-label {
  display: block;
  font-size: 0.95rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.setting-description {
  display: block;
  font-size: 0.85rem;
  color: var(--text-secondary);
}

/* 自定义抽屉样式 */
.ant-drawer .ant-drawer-content {
  background-color: var(--card-bg);
}

.ant-drawer .ant-drawer-header {
  padding: 16px 24px;
  background-color: var(--card-bg);
  border-bottom: 1px solid var(--border);
}

.ant-drawer .ant-drawer-title {
  font-size: 1rem;
  font-weight: 600;
}

.ant-drawer .ant-drawer-body {
  padding: 20px;
}

/* 设置项中的开关样式 */
.setting-item .ant-switch {
  min-width: 52px;
  height: 24px;
}

/* 响应式调整 */
@media (max-width: 600px) {
  .ant-drawer {
    max-width: 100%;
  }

  .settings-content {
    gap: 20px;
  }

  .setting-header {
    gap: 12px;
  }

  .setting-label {
    font-size: 0.9rem;
  }

  .setting-description {
    font-size: 0.8rem;
  }
} 