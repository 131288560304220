:root {
  --background: #f8fafc;
  --card-bg: #ffffff;
  --text-primary: #1a1a1a;
  --text-secondary: #666;
  --accent: #3b82f6;
  --shadow: rgba(0, 0, 0, 0.08);
  --border: #eaeaea;
  --header-bg: rgba(245, 245, 245, 0.98);
  --header-border: rgba(0, 0, 0, 0.1);
  --icon-color: #666;
}

[data-theme="dark"] {
  --background: #111827;
  --card-bg: #1f2937;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border: #374151;
  --shadow: rgba(0, 0, 0, 0.2);
  --header-bg: rgba(17, 24, 39, 0.98);
  --header-border: rgba(255, 255, 255, 0.1);
  --icon-color: #9ca3af;
  --hover-bg: rgba(55, 65, 81, 0.5);
}

html {
  background-color: var(--background);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: var(--background);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* RTL Support */
[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

[dir="rtl"] .header-actions {
  flex-direction: row-reverse;
}

[dir="rtl"] .setting-header {
  flex-direction: row-reverse;
}

[dir="rtl"] .setting-title {
  text-align: right;
}

[dir="rtl"] .ant-drawer-header-title {
  flex-direction: row-reverse;
}

[dir="rtl"] .ant-drawer-close {
  margin-right: auto;
  margin-left: 0;
}

[dir="rtl"] .clock-header {
  flex-direction: row-reverse;
}